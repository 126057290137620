import {mapGetters, mapActions, mapMutations} from 'vuex';

export default {
  name: 'leftoverTypes.list',
  data() {
    return {}
  },
  components: {},
  created() {
    this.fetchLeftoverTypes();
  },
  mounted() {},
  computed: {
    ...mapGetters({
      leftoverTypes: 'leftoverTypes/leftoverTypes',
      isLeftoverTypesLoading: 'leftoverTypes/isLeftoverTypesLoading',
    }),
  },
  watch: {},
  methods: {
    ...mapActions({
      fetchLeftoverTypes: 'leftoverTypes/LEFTOVER_TYPES_REQUEST_LIST_GET',
      deleteLeftoverType: 'leftoverTypes/LEFTOVER_TYPES_REQUEST_DELETE',
    }),
    ...mapMutations({
      setLeftoverType: 'leftoverTypes/LEFTOVER_TYPES_DATA',
    }),
    deleteItem(item) {
      this.$set(item, 'isLoading', true);
      this.deleteLeftoverType(item.id).then(() => {
        this.$toasted.success(this.$t('leftoverTypesDeleted'));
      }).catch(error => {
        if (error.response.status === 422) {
          this.$toasted.error(error.response.data.message);
        }
      }).finally(()=>{
        this.$set(item, 'isLoading', false);
      });
    },
    edit(item) {
      this.setLeftoverType(item)
      this.$router.push({name: 'leftoverTypes.edit', params: {leftoverTypeId: item.id}}).catch(() => {console.log();});
    },
  },
  destroyed() {},
}
